import { useEffect, useReducer } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AdminAddStudentLecture() {
  const { lectureID } = useParams();
  const navigate = useNavigate();

  function reducer(state, action) {
    switch (action.type) {
      case "setErrors": {
        return {
          ...state,
          errors: action.payload,
        };
      }

      case "setSubmitAddStudentToLecture": {
        return {
          ...state,
          submitAddStudentToLecture: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddStudentToLecture: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  const [
    AddStudentToLectureSuccess,
    AddStudentToLectureErrors,
    AddStudentToLectureLoading,
  ] = useAxios(
    process.env.REACT_APP_ADMIN_ADD_STUDENT_TO_LECTURE_API,
    "POST",
    state.submitAddStudentToLecture.flag,
    state.submitAddStudentToLecture.dependency,
    state.submitAddStudentToLecture.data,
    true
  );

  useEffect(() => {
    //!---add actions ----
    if (AddStudentToLectureSuccess) {
      navigate(-1);
    }
  }, [AddStudentToLectureSuccess, navigate]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: "onTouched" });

  const onSubmit = (data) => {
    const finalData = {
      ...data,
      lecture_id: lectureID,
    };
    dispatch({
      type: "setSubmitAddStudentToLecture",
      payload: {
        flag: "AddStudentToLecture",
        dependency: !state.submitAddStudentToLecture.dependency,
        data: finalData,
      },
    });
  };

  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="Add Student | Mr.Ahmed Rashad"></HelmetTags>

      <div className="form-container my-20 flex w-full flex-col items-center">
        <h2 className="my-20 text-center text-3xl font-semibold">
          Please fill this inputs to add student{" "}
        </h2>

        <form
          encType="multipart/form-data"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="md:max-w-3/4 flex  w-3/4  flex-col items-start justify-center gap-10 rounded-3xl bg-blue-200 p-16 shadow-lg shadow-red-200/50"
        >
          <div className="name flex w-full flex-col items-start justify-center  gap-2  md:w-full">
            <label htmlFor="mobile">Mobile Number</label>
            <input
              className="signin-inputs w-full pl-4    "
              type="number"
              id="mobile"
              placeholder="Mobile Number"
              name="mobile"
              autoComplete="on"
              {...register("mobile", {
                required: true,
                maxLength: 40,
                minLength: 3,
              })}
            />
            {errors.mobile && (
              <p className="w-full text-end text-[12px] text-red-900  ">
                {errors.mobile.type === "required" && "this field is required"}
              </p>
            )}

            {
              //!-------server errors -----

              AddStudentToLectureErrors &&
                AddStudentToLectureErrors?.response?.data?.errors?.mobile && (
                  <p className="w-full text-end text-[12px] text-red-900  ">
                    {
                      AddStudentToLectureErrors?.response?.data?.errors
                        ?.mobile[0]
                    }
                  </p>
                )
            }
          </div>

          {/** submit */}
          <button
            disabled={
              !isValid ||
              (AddStudentToLectureLoading &&
                state.submitAddStudentToLecture.flag)
            }
            className=" submit mt-6 w-full "
            type="submit"
          >
            {AddStudentToLectureLoading &&
            state.submitAddStudentToLecture.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p> Add Student</p>
            )}
          </button>
        </form>
      </div>
    </section>
  );
}

export default AdminAddStudentLecture;
