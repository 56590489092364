import { useState } from "react";
import QuestionModalAnswer from "./QuestionModalAnswer";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faCircleCheck,
  faCircleXmark,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { Loader } from "../../../../MainComponents";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags";

const AdminModalAnswer = ({ exam }) => {
  const [collapse, setCollapse] = useState(false);
  const [activeQuestionId, setActiveQuestionId] = useState(0);
  const { examID } = useParams();
  const navigate = useNavigate();

  const api = process.env.REACT_APP_ADMIN_TRIAL_EXAM_VIEW_MODEL_ANSWER_API;

  const [modalAnswerData, modalAnswerErrors, modalAnswerLoading] = useAxios(
    `${api}${examID}`,
    "GET",
    "GET",
    ""
  );

  function handleOnClickQuestion(id) {
    handelOpenCollapse();
    setActiveQuestionId(id);
  }

  function handelOpenCollapse() {
    setCollapse(true);
  }

  function handelCloseCollapse() {
    setCollapse(false);
  }

  if (modalAnswerLoading) return <Loader />;
  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 sm:px-10 ">
      <HelmetTags title="Model Answer | Mr.Ahmed Rashad"></HelmetTags>
      <h2 className="mb-20 w-full  text-4xl font-bold md:text-center">
        Student Model Answer
      </h2>
      <QuestionModalAnswer
        collapse={collapse}
        onCloseCollapse={handelCloseCollapse}
        activeQuestionId={activeQuestionId}
        question={modalAnswerData?.data[activeQuestionId]}
      />
      <div className="all-questions flex h-full flex-col items-center overflow-y-auto  p-[40px] transition-all  duration-300 ease-in-out w-full md:order-2 md:w-full">
        {modalAnswerErrors && (
          <div className="flex justify-center flex-col">
            <p>{modalAnswerErrors}</p>
            <button
              onClick={() => navigate(-1)}
              className="flex justify-center my-6"
            >
              <div className="collapse-btn top-[96px] flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-accent text-light shadow-md shadow-black/40 duration-300 hover:scale-110 hover:text-light">
                <FontAwesomeIcon
                  className={`transition-all duration-300 ease-in-out hover:scale-110`}
                  icon={faChevronRight}
                />
              </div>
            </button>
          </div>
        )}
        {!modalAnswerLoading && !modalAnswerErrors && (
          <>
            <p className="my-[20px] text-center text-secondary dark:text-white">
              Click To Show Your Answer!
            </p>

            <div className="response-questions myactual-questions-container my-[40px] w-1/2">
              {modalAnswerData?.data?.map((question, index) => (
                <div
                  key={index}
                  onClick={() => handleOnClickQuestion(index)}
                  className={`single-ques-square  mb-[4px] flex h-[44px] w-[44px] cursor-pointer items-center justify-center rounded-md text-[18px] font-bold text-[#fff] ${
                    question.is_correct ? "bg-lime-500" : "bg-secondary"
                  }  `}
                >
                  {index + 1}
                </div>
              ))}
            </div>
            <div className="indicators flex w-full items-center justify-center gap-[20px] border-t-2 border-t-lime-500 py-[20px] sm:flex-col">
              <div className="correct flex items-center gap-[8px] justify-self-center text-[18px] text-lime-500">
                <FontAwesomeIcon icon={faCircleCheck} />
                <p>Correct Answer</p>
              </div>
              <div className="correct flex items-center gap-[8px] justify-self-center text-[18px] text-secondary">
                <FontAwesomeIcon icon={faCircleXmark} /> <p>Wrong Answer</p>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default AdminModalAnswer;
