import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";

const Question = ({ state, dispatch, homework, exam }) => {
  const { ID } = useParams();

  const checkApi = homework
    ? process.env.REACT_APP_STUDENT_HOMEWORK_UPDATE_API
    : exam
      ? process.env.REACT_APP_STUDENT_TRIAL_EXAM_UPDATE_API
      : process.env.REACT_APP_STUDENT_QUIZZ_UPDATE_API;

  const handleSelectAnswer = (answer, questionId, n) => {
    dispatch({
      type: "setAnsweredQuestions",
      payload: { id: questionId, order: answer, n },
    });
    async function fetchData() {
      try {
        let res = await axios.post(checkApi, {
          id: questionId,
          order: answer,
          lecture_id: ID,
          n: n,
        });
      } catch (error) {
        console.error("Error:", error);
        toast.error(error.response.data.message);
      }
    }

    fetchData();

    dispatch({
      type: "setIsSelectedAnswer",
      payload: { questionId, answer, n },
    });
  };

  return (
    <div className="container max-w-[600px]    ">
      <div className="current-question">
        {`${state.currentQuestionIndex + 1}.`}
        <div
          dangerouslySetInnerHTML={{
            __html: state.questions[state.currentQuestionIndex]?.question,
          }}
        />
      </div>

      <div className="current-question-degree text-end">
        <p> {state.questions[state.currentQuestionIndex]?.degree} Degree</p>
      </div>

      <div className="current-question-answers">
        {state.questions[state.currentQuestionIndex]?.answers.map(
          (answer, i) => (
            <div
              key={i}
              className={`${
                state.questions[state.currentQuestionIndex].selected_answer ===
                  answer.order && "selected"
              }`}
              onClick={() =>
                handleSelectAnswer(
                  answer.order,
                  state.questions[state.currentQuestionIndex].id,
                  state.questions[state.currentQuestionIndex].n
                )
              }
            >
              <span>{answer.order} </span>
              <p dangerouslySetInnerHTML={{ __html: answer.answer }} />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Question;
