import useTable from "../../../../MainComponents/Hooks/useTable.jsx";
import { useAuthState } from "../../../../MainComponents/GlobalContext.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import { useEffect, useState } from "react";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import Loader from "../../../../MainComponents/Loader.jsx";

function Students() {
  const [academiclevel, setAcademiclevel] = useState("11");

  // const table = useTable("students", "", "", academiclevel, "", academiclevel);
  const [reFetch, setReFetch] = useState(false);
  const [academicType, setAcademicType] = useState("Azhar");
  const [filterdData, setFilteredData] = useState([]);

  const [getAllStudent, getAllStudentErrors, getAllStudentloading] = useAxios(
    `${process.env.REACT_APP_ADMIN_STUDENTS_TAB_API}${academiclevel}`,
    "GET",
    "GET",
    academiclevel,
    "",
    ""
  );

  const table = useTable(
    "students",
    "",
    "",
    academiclevel,
    filterdData,
    "",
    true,
    filterdData,
    reFetch,
    setReFetch
  );

  useEffect(() => {
    if (getAllStudent?.data) {
      const data =
        +academiclevel === 3
          ? getAllStudent?.data?.filter((data) => data.section === academicType)
          : getAllStudent?.data;
      setFilteredData(data);
    }
  }, [academicType, academiclevel, getAllStudent?.data]);

  console.log("fff");

  if (getAllStudentloading) return <Loader />;

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 bg-mainBackground dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="Students | Mr Ahmed Rashad"></HelmetTags>
      <div className="mb-20 flex flex-col items-end gap-20">
        <h2 className="w-full text-4xl font-bold md:text-center">Students</h2>
        <div className="flex w-full   gap-10 md:justify-center sm:flex-col-reverse sm:items-center">
          <button
            onClick={() => {
              setAcademiclevel("11");
            }}
            className={` rounded-xl border-2 border-secondary  px-2 py-1 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academiclevel === "11" && "bg-secondary text-light"
            }`}
          >
            First preparatory{" "}
          </button>
          <button
            onClick={() => {
              setAcademiclevel("22");
            }}
            className={` rounded-xl border-2 border-secondary  px-2 py-1 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academiclevel === "22" && "bg-secondary text-light"
            }`}
          >
            Second preparatory{" "}
          </button>
          <button
            onClick={() => {
              setAcademiclevel("33");
            }}
            className={` rounded-xl border-2 border-secondary  px-2 py-1 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academiclevel === "33" && "bg-secondary text-light"
            }`}
          >
            Third preparatory{" "}
          </button>
          <button
            onClick={() => {
              setAcademiclevel("1");
            }}
            className={` rounded-xl border-2 border-secondary   px-2 py-1 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academiclevel === "1" && "bg-secondary text-light"
            }`}
          >
            First grade
          </button>
          <button
            onClick={() => {
              setAcademiclevel("2");
            }}
            className={` rounded-xl border-2 border-secondary  px-2 py-1 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academiclevel === "2" && "bg-secondary text-light"
            }`}
          >
            Second grade
          </button>
          <button
            onClick={() => {
              setAcademiclevel("3");
            }}
            className={` rounded-xl border-2 border-secondary   px-2 py-1 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academiclevel === "3" && "bg-secondary text-light"
            }`}
          >
            Third grade
          </button>
        </div>
        {+academiclevel === 3 ? (
          <div className="flex w-full   gap-10 md:justify-center sm:flex-col-reverse sm:items-center">
            <button
              onClick={() => {
                setAcademicType("Azhar");
              }}
              className={` rounded-xl border-2 border-secondary  px-2 py-1 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
                academicType === "Azhar" && "bg-secondary text-light"
              }`}
            >
              Azhar
            </button>
            <button
              onClick={() => {
                setAcademicType("Arts");
              }}
              className={` rounded-xl border-2 border-secondary  px-2 py-1 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
                academicType === "Arts" && "bg-secondary text-light"
              }`}
            >
              Arts
            </button>
            <button
              onClick={() => {
                setAcademicType("Math");
              }}
              className={` rounded-xl border-2 border-secondary  px-2 py-1 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
                academicType === "Math" && "bg-secondary text-light"
              }`}
            >
              Math
            </button>
          </div>
        ) : null}
      </div>
      {/**
       * //!---------Table-----
       */}
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default Students;
