import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import useAxios from "../../../../MainComponents/Hooks/useAxios";

export const BunnyVideo = ({ src, history_id }) => {
  const [watchTime, setWatchTime] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [submitDecreaseWatch, setSubmitDecreaseWatch] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [decreaseWatchSuccess, decreaseWatchErrors, decreaseloading] = useAxios(
    process.env.REACT_APP_STUDENT_DECREASE_WATCHE_IN_COURSE_API,
    "POST",
    submitDecreaseWatch.flag,
    submitDecreaseWatch.dependency,
    submitDecreaseWatch.data
  );

  const handlePlay = () => {
    if (!intervalId) {
      const id = setInterval(() => {
        setWatchTime((prevTime) => prevTime + 1);
      }, 1000); // Update every second
      setIntervalId(id);
    }
  };

  const handlePause = () => {
    clearInterval(intervalId);
    setIntervalId(null);
  };

  const handleEnded = () => {
    clearInterval(intervalId);
    setIntervalId(null);
    console.log("Video has ended");
  };

  console.log(watchTime);

  useEffect(() => {
    setWatchTime(0);
  }, [src]);

  useEffect(() => {
    if (watchTime === 1200) {
      setSubmitDecreaseWatch({
        flag: "deleteQuestion",
        dependency: !submitDecreaseWatch.dependency,
        data: { id: history_id },
      });
    }
  }, [history_id, watchTime]);

  return (
    <div className="bunny-video-wrapper">
      <ReactPlayer
        url={src}
        controls
        width="100%"
        height="100%"
        playing={true}
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={handleEnded}
        config={{
          file: {
            forceHLS: true,
          },
        }}
      />
    </div>
  );
};
