import { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function AddEditChapter({ edit }) {
  const [values, setValues] = useState(null);
  const { chapterID } = useParams();
  const navigate = useNavigate();

  function reducer(state, action) {
    switch (action.type) {
      case "setErrors": {
        return {
          ...state,
          errors: action.payload,
        };
      }

      case "setSubmitAddEditChapter": {
        return {
          ...state,
          submitAddEditChapter: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditChapter: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  let api = edit
    ? process.env.REACT_APP_ADMIN_EDIT_CHAPTER_API
    : process.env.REACT_APP_ADMIN_ADD_CHAPTER_API;
  //!--------- add edit Chapter -------

  const [ChapterAddEditSuccess, ChapterAddEditErrors, ChapterAddEditLoading] =
    useAxios(
      api,
      "POST",
      state.submitAddEditChapter.flag,
      state.submitAddEditChapter.dependency,
      state.submitAddEditChapter.data,
      true
    );

  //!--------- get the Chapter info for editing -------

  const [ChapterInfo, ChapterInfoErrors] = useAxios(
    `${process.env.REACT_APP_ADMIN_CHAPTER_INFO_API}${chapterID}`,
    "GET",
    chapterID,
    chapterID
  );
  useEffect(() => {
    if (ChapterInfo) {
      let temp = ChapterInfo.data;
      delete temp.img;
      setValues({
        ...temp,
        visibility: temp.visibility === 1 ? true : false,
      });
    }
  }, [ChapterInfo]);

  useEffect(() => {
    //!---add actions ----

    if (ChapterAddEditSuccess) {
      navigate(-1);
      reset();
    }
    //! make the dependency false again to listen to the next try to submit
  }, [ChapterAddEditSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched", values });

  const onSubmit = (data) => {
    const finalData = chapterID
      ? {
          ...data,
          id: chapterID,
          visibility: +data?.visibility,
          img: data?.img[0] || null,
        }
      : {
          ...data,
          img: data?.img[0] || null,
        };
    dispatch({
      type: "setSubmitAddEditChapter",
      payload: {
        flag: "AddEditChapter",
        dependency: !state.submitAddEditChapter.dependency,
        data: finalData,
      },
    });
  };

  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="Chapters | Mr Ahmed Rashad"></HelmetTags>

      <div className="form-container my-20 flex w-full flex-col items-center">
        <h2 className="my-20 text-center text-3xl font-semibold">
          {chapterID
            ? "Modify class information"
            : "Please fill in the information to add the chapter"}
        </h2>

        <form
          encType="multipart/form-data"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="md:max-w-3/4 flex  w-3/4  flex-col items-start justify-center gap-10 rounded-3xl bg-blue-200 p-16 shadow-lg shadow-blue-200/50"
        >
          {/** Name stage filed */}
          <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
            <div
              className={`flex w-1/2 flex-col items-end justify-center gap-2 md:order-2  md:w-full `}
            >
              <label className="w-full truncate" htmlFor="stage">
                Classroom
              </label>
              <select
                name="stage"
                id="stage"
                {...register("stage", { required: true })}
              >
                <option value="11">First preparatory</option>
                <option value="22">Second preparatory</option>
                <option value="33">Third preparatory </option>
                <option value="1">First secondary</option>
                <option value="2">Second secondary</option>
                <option value="3">Third secondary</option>
              </select>

              {errors.stage && (
                <p className="text-[12px] text-blue-900 ">
                  {errors.stage.type === "required" &&
                    "Please fill out this field"}
                </p>
              )}
              {
                //!-------server errors -----

                ChapterAddEditErrors &&
                  ChapterAddEditErrors?.response?.data?.errors?.stage && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {ChapterAddEditErrors?.response?.data?.errors?.stage[0]}
                    </p>
                  )
              }
            </div>
            <div className="name flex w-1/2 flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="name">Name</label>
              <input
                className="signin-inputs w-full pl-4    "
                type="text"
                id="name"
                placeholder="Name"
                name="name"
                autoComplete="on"
                {...register("name", {
                  required: true,
                  maxLength: 40,
                  minLength: 3,
                })}
              />
              {errors.name && (
                <p className="w-full  text-[12px] text-blue-900  ">
                  {errors.name.type === "required" &&
                    "Please fill out this field"}
                  {errors.name.type === "maxLength" &&
                    "The maximum number of characters is 40 characters"}
                  {errors.name.type === "minLength" &&
                    "Please write at least 3 characters"}
                </p>
              )}

              {
                //!-------server errors -----

                ChapterAddEditErrors &&
                  ChapterAddEditErrors?.response?.data?.errors?.name && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {ChapterAddEditErrors?.response?.data?.errors?.name[0]}
                    </p>
                  )
              }
            </div>
          </div>

          {edit && (
            <div className="flex  w-full items-start justify-end gap-16 md:flex-col md:gap-10 ">
              <div className=" visibility flex w-full flex-col items-end justify-center gap-2 md:w-full">
                <div className="flex w-full items-center justify-end gap-10">
                  <label className="visibility-switch">
                    <input
                      className=""
                      id="visibility"
                      name="visibility"
                      {...register("visibility", {
                        required: false,
                      })}
                      type="checkbox"
                    />
                    <span className="visibility-slider">
                      <svg
                        className="slider-icon"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <path fill="none" d="m4 16.5 8 8 16-16"></path>
                      </svg>
                    </span>
                  </label>
                  <div>
                    <label
                      className="w-full cursor-pointer truncate"
                      htmlFor="visibility"
                    >
                      Visibility
                    </label>
                  </div>
                </div>

                {errors.visibility && (
                  <p className="text-[12px] text-white ">
                    {errors.visibility.type === "required" &&
                      "برجاء ملئ هذا الحقل"}{" "}
                  </p>
                )}
                {
                  //!-------server errors -----

                  ChapterAddEditErrors &&
                    ChapterAddEditErrors?.response?.data?.errors
                      ?.visibility && (
                      <p className="w-full text-end text-[12px] text-white  ">
                        {
                          ChapterAddEditErrors?.response?.data?.errors
                            ?.visibility[0]
                        }
                      </p>
                    )
                }
              </div>
            </div>
          )}

          {/** course Image  */}
          <div className="flex w-full flex-col items-end gap-2">
            <label className="w-full" htmlFor="img">
              Upload Course image
            </label>

            <input
              id="img"
              className="signin-inputs   w-full"
              type="file"
              accept="image/jpg, image/jpeg, image/png, image/webp"
              name="img"
              {...register("img", {
                required: values ? false : true,
                validate: (value) => !(value[0]?.size > 5000000),
              })}
            />

            {errors.img && (
              <p className="mt-2 w-full  text-[12px] text-blue-900">
                {errors.img.type === "required" &&
                  "Please add a picture of the class"}
                {errors.img.type === "validate" && "Maximum image size is 5MB"}
              </p>
            )}
            {
              //!-------server errors -----

              ChapterAddEditErrors &&
                ChapterAddEditErrors?.response?.data?.errors?.img && (
                  <p className="w-full  text-[12px] text-blue-900  ">
                    {ChapterAddEditErrors?.response?.data?.errors?.img[0]}
                  </p>
                )
            }
          </div>
          <div className=" flex w-full flex-col items-end justify-center gap-2 ">
            <label className="w-full truncate " htmlFor="description">
              Description
            </label>
            <textarea
              className=" resize-none"
              {...register("description", {
                maxLength: 155,
                minLength: 3,
              })}
              name="description"
              id="description"
              cols="40"
              rows="5"
            ></textarea>
            {errors.description && (
              <p className="text-[12px] text-blue-900 ">
                {errors.description.type === "maxLength" &&
                  "The maximum number of characters is 155 characters"}
                {errors.description.type === "minLength" &&
                  "Please write at least 3 characters"}
              </p>
            )}
            {
              //!-------server errors -----

              ChapterAddEditErrors &&
                ChapterAddEditErrors?.response?.data?.errors?.description && (
                  <p className="w-full  text-[12px] text-blue-900  ">
                    {
                      ChapterAddEditErrors?.response?.data?.errors
                        ?.description[0]
                    }
                  </p>
                )
            }
          </div>
          {/** submit */}

          <button
            disabled={
              !isValid ||
              (ChapterAddEditLoading && state.submitAddEditChapter.flag)
            }
            className=" submit mt-6 w-full "
            type="submit"
          >
            {ChapterAddEditLoading && state.submitAddEditChapter.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p>
                {" "}
                {chapterID
                  ? "Confirm modification of class data"
                  : "Add chapter"}
              </p>
            )}
          </button>
        </form>
      </div>
    </section>
  );
}

export default AddEditChapter;
